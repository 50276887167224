<template>
  <nav v-if="prev || next">
    <div class="contain-override contain-override-really">
      <div class="flex justify-between items-center">
        <div
          class="prev justify-self-start"
          :class="{ 'has-link': prev?.path }"
        >
          <LinkTo v-if="prev" class="link" :to="prev.path">
            {{ t('prev') }}
          </LinkTo>
        </div>
        <div class="next justify-self-end" :class="{ 'has-link': next?.path }">
          <LinkTo v-if="next" class="link" :to="next.path">
            {{ t('next') }}
          </LinkTo>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { PageLinks } from '~/types'

defineProps<Pick<PageLinks, 'prev' | 'next'>>()

const { t } = useI18n()
</script>
<style lang="postcss" scoped>
.contain-override-really {
  @media (min-width: 500px) {
    > * {
      max-width: 100vw;
      padding: 0;
    }
  }
}

.link {
  transition: background 1s;
}

.has-link {
  @apply leading-none -mx-4 -mt-4 rounded-l-full rounded-r-none relative bg-white hover:bg-brand-turquoise py-2 pr-16 pl-4 text-black text-sm hover:text-white;

  @media screen and (min-width: 500px) {
    @apply mx-0;
  }

  a::after {
    background-image: url('data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22chevron-right%22%20class%3D%22svg-inline--fa%20fa-chevron-right%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M285.476%20272.971L91.132%20467.314c-9.373%209.373-24.569%209.373-33.941%200l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505%20256%2034.484%20101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373%2024.569-9.373%2033.941%200L285.475%20239.03c9.373%209.372%209.373%2024.568.001%2033.941z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 14px;
    position: absolute;
    left: auto;
    right: 24px;
    width: 4em;
    transform: translateX(-1em);
    transition: transform 0.5s;
  }

  &:hover a::after {
    background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2025.4.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20focusable%3D%22false%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22320px%22%20height%3D%22512px%22%20viewBox%3D%220%200%20320%20512%22%20style%3D%22enable-background%3Anew%200%200%20320%20512%3B%22%0A%09%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M285.5%2C273L91.1%2C467.3c-9.4%2C9.4-24.6%2C9.4-33.9%2C0l-22.7-22.7c-9.4-9.4-9.4-24.5%2C0-33.9l154-154.7l-154-154.7%0A%09c-9.3-9.4-9.3-24.5%2C0-33.9l22.7-22.7c9.4-9.4%2C24.6-9.4%2C33.9%2C0L285.5%2C239C294.8%2C248.4%2C294.8%2C263.6%2C285.5%2C273z%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    transform: translateX(0);
  }

  &.prev {
    @apply rounded-l-none rounded-r-full py-2 pr-4 pl-16;

    background-position: left;
    a::after {
      left: 24px;
      right: auto;
      transform: rotate(180deg) translateX(-1em);
    }

    &:hover a::after {
      transform: rotate(180deg) translateX(0);
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "prev": "PREV",
    "next": "NEXT"
  },
  "es": {
    "prev": "ANTERIOR",
    "next": "SIGUIENTE"
  }
}
</i18n>
