<template>
  <NuxtErrorBoundary @error="logError">
    <section v-if="data?.length" class="space-y-8">
      <hr />
      <h2 class="text-4xl font-sans font-light">
        {{ t('More') }}
      </h2>
      <LytGridFourUp :length="data.length">
        <template #item="{ idx }">
          <template v-for="card in [getCardAt(data, idx)]" :key="card?.uuid">
            <Card v-if="card" :="card" />
          </template>
        </template>
      </LytGridFourUp>
    </section>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import { getCardAt } from '~/utils/get-card'
import * as logger from '~/utils/logging'

const props = defineProps<{
  uuid: string
  lang: string
  limit?: number
}>()
const { t, locale } = useI18n()

const { data, error } = useLazyFetch(`/api/data/content/${props.uuid}/mlt`, {
  params: {
    lang: props.lang || locale,
    limit: props.limit || 4,
  },
})

const logError = () => {
  logger.error('[mlt] error', unref(error))
}
</script>

<i18n lang="json">
{
  "en": {
    "More": "More"
  },
  "es": {
    "More": "Más como este"
  }
}
</i18n>
